/**
 * About page
 */
/* Import section */
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Blockmapdoctors from "../components/blockmapdoctors"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Sectionctafooter from "../components/sectionctafooter"
import Sectionh1content from "../components/sectionh1content"
import Seo from "../components/seo"
import Iconvaluescommitment from "../images/icons/iconvaluescommitment.inline.svg"
import Iconvaluesinnovation from "../images/icons/iconvaluesinnovation.inline.svg"
import Iconvaluespassion from "../images/icons/iconvaluespassion.inline.svg"
import "../styles/pages/about-us.scss"

/* Page function declaration */
const AboutPage = ({ data }) => {

  //Const data ACF
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageAbout

  //CTA Footer page CONTACT
  var ctaFooterContact = data.options.acfPageOptions.repeaterCtaFooter.filter(function (ctaFooter) {
    return ctaFooter.typeCtaFooter === "contact"
  })

  return (
    <Layout className="about">
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
      />

      {/* Section Hero */}
      <Hero classNamePage="about" colorHero="green-light" colorShape="green" />

      {/* Section H1 */}
      <Sectionh1content titleH1={dataAcf.titleGeneralAbout} classNameH1="about green-light" vc="true">
        <div className="content-history">
          <StaticImage src="../images/logos/logo_hellohealth.png" alt="Logo Hello Health" layout="fixed" width={260} quality={100} />
          <div className="box-history intro-history" dangerouslySetInnerHTML={{ __html:dataAcf.contentGeneralAbout}} />
          <div className="box-history" dangerouslySetInnerHTML={{ __html:dataAcf.contentHistoryAbout}} />
          <div className="separator"></div>
          <div className="box-history" dangerouslySetInnerHTML={{ __html:dataAcf.contentPcAbout}} />
          <div className="box-history backline">
            <StaticImage src="../images/logos/logo_backline.png" alt="Logo Backline" layout="fixed" width={260} quality={100} />
            <div dangerouslySetInnerHTML={{ __html:dataAcf.contentBacklineAbout}} />
          </div>
        </div>

      </Sectionh1content >

      {/* Section Healthcare */}
      <section className="section-healthcare">
        <div className="content-healthcare">
          <h2 className="title-level-1">{dataAcf.titleHealthcare}</h2>
          <div dangerouslySetInnerHTML={{ __html:dataAcf.contentHealthcare}} />
          <h3 className="title-level-2">{dataAcf.subtitleHealthcare}</h3>
        </div>
        <Blockmapdoctors listStates={dataAcf.repeaterStates} />
      </section>

      {/* Section Values */}
      <section className="section-values">
        <div className="content-values">
          <h2 className="title-level-1">{dataAcf.titleValues}</h2>
          <div className="box-icons-values">
            <div className="icon-values">
              <span><Iconvaluescommitment className="svg-icon-values"/></span>
            </div>
            <div className="icon-values">
              <span><Iconvaluespassion className="svg-icon-values"/></span>
            </div>
            <div className="icon-values">
              <span><Iconvaluesinnovation className="svg-icon-values"/></span>
            </div>
          </div>
          <div className="emphase_values" dangerouslySetInnerHTML={{ __html:dataAcf.content1Values}} />
          <div className="separator"></div>
          <div dangerouslySetInnerHTML={{ __html:dataAcf.content2Values}} />
        </div>
      </section>

      {/* Section CtaFooter */}
      {ctaFooterContact.map(function (ctafooter, i) {
        return <Sectionctafooter key={i} typeCta="contact" titleCta={ctafooter.titleCtaFooter} labelCta={ctafooter.labelCtaFooter} classCta="bluegrey-vc" />
      })}
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "about"}) {
      acfPageAbout {
        titleGeneralAbout
        contentGeneralAbout
        contentHistoryAbout
        contentPcAbout
        contentBacklineAbout
        titleHealthcare
        contentHealthcare
        subtitleHealthcare
        repeaterStates {
          titleState
        }
        titleValues
        content1Values
        content2Values
      }
      seo {
        metaDesc
        title
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterCtaFooter {
          typeCtaFooter
          titleCtaFooter
          labelCtaFooter
        }
      }
    }
  }
`
/* Export page informations */
export default AboutPage



