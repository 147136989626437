/**
 * Class element
 * Block map doctors component
 * Display an image or a list of states of doctors VC according the device (desktop, tablet, mobile)
 */
/* Import section */
import { StaticImage } from "gatsby-plugin-image"
import React, { Component } from "react"
import Iconpinmap from "../images/icons/iconpinmap.inline.svg"
import Iconvconlinedoctor from "../images/icons/iconvconlinedoctor.inline.svg"
import Shapeleafblue from "../images/shapes/shapeleafblue.inline.svg"
import "../styles/components/blockmapdoctors.scss"


/* Declaration class */
class Blockmapdoctors extends Component {

  constructor() {
    super()
    this.state = {
      width: 0
    }
  }

  componentDidMount() {
    this.handleWindowSizeChange()
    window.addEventListener('resize', this.handleWindowSizeChange)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    if(window.innerWidth !== this.state.width) {
      this.setState({
        width: window.innerWidth
      })
    }
  }

  render () {
    const isMobile = this.state.width <= 767
    var arrStates = this.props.listStates

    return (
      <div className="content-map-doctors">
        <div className="shape-leaf-blue" >
          <Shapeleafblue />
        </div>
        <div className="pin-map">
         <Iconpinmap />
         <span>
           <Iconvconlinedoctor />
         </span>
        </div>
        {!isMobile ? (
          <StaticImage className="map-states-doctors" src="../images/content/map_doctors.jpg" alt="map USA doctors Virtual Care" layout="fixed" width={700}  />
        ) : (
          <ul className="list-states-doctors">
            {arrStates.map((state, index) => (
              <li key={index}>{state.titleState}</li>
            ))}
          </ul>
        )}
      </div>
    )
  }
}

/* Export class */
export default Blockmapdoctors
